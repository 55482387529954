import React from 'react'

export default function SectionExplainer() {
  return (
    <div>


<section class=" pt-16 lg:pt-24 lg:pb-8  bg-indigo-900 overflow-hidden" style={{fontFamily:'poppins'}}>
  <div class="container  lg:px-4 mx-auto">
    <h2 class=" ml-12 mb-8 text-white text-2xl md:text-4xl lg:ml-24 font-bold font-heading tracking-px-n leading-tight md:max-w-lg">Our skills match perfectly to your requirements</h2>
    <div class="flex flex-wrap m-12">
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-indigo-100 rounded-full">
              <span class="absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2">1</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-200"></div>
          </div>
        </div>
        <h3 class="text-lg text-white font-semibold leading-normal md:max-w-xs">We deliver intuitive reports through latest tools and technology</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl text-white font-bold bg-sky-600 rounded-full">
              <img class="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt=""/>
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-200"></div>
          </div>
        </div>
        <h3 class="text-lg text-white font-semibold leading-normal md:max-w-xs">Our simple strategic applications can help you make business decisions</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-indigo-100 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-200"></div>
          </div>
        </div>
        <h3 class="text-lg text-white font-semibold leading-normal md:max-w-xs">With machine learning capabilities, your complex data can be simplified</h3>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 p-8">
        <div class="flex flex-wrap items-center mb-2 lg:mb-7 -m-2">
          <div class="w-auto p-2">
            <div class="relative w-14 h-14 text-2xl font-bold font-heading bg-sky-600 rounded-full">
              <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">4</span>
            </div>
          </div>
          <div class="flex-1 p-2">
            <div class="w-full h-px bg-gray-200"></div>
          </div>
        </div>
        <h3 class="text-lg text-white font-semibold leading-normal md:max-w-xs">Our solutions are highly specific to your needs, and business goals</h3>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
