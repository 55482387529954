import React from 'react'

import teamone from '../../../static/images/teamhead.png'
import teamtwo from '../../../static/images/team1.png'
import teamthree from '../../../static/images/team3.png'
import teamfour from '../../../static/images/team4.png'

export default function team() {
  return (
    <div>
        <section class="relative py-24 lg:py-28 px-6 bg-white" style={{fontFamily:"Poppins"}}>
  <img class="hidden lg:block absolute top-0 left-0 mt-20" src="zeus-assets/icons/dots/blue-dot-left-bars.svg" alt=""/>
  <img class="hidden lg:block absolute top-0 right-0 mt-52" src="zeus-assets/icons/dots/yellow-dot-right-shield.svg" alt=""/>
  <div class="container px-5 mx-auto">
    <div class="max-w-3xl mx-auto mb-12 lg:mb-20 text-center">
      <span class="text-sm text-sky-600 font-bold">Our Team</span>
      <h2 class="mt-8 mb-4 lg:mt-4 lg:mb-6 text-2xl lg:text-4xl font-semibold font-heading text-indigo-900">Our people are the heart of our firm</h2>
      <p class=" text-sm lg:text-lg text-gray-600 font-medium">Our passion drives us to create a great result for our customers. Our Goal is to benefit and enable our clients to make better decisions to protect and grow their organizations</p>
    </div>
    <div class="flex flex-wrap -m-3 lg:mx-2">
      <div class="w-full lg:w-1/2 p-3">
        <div class="p-6 border rounded-xl bg-gray-100">
          <div class="flex flex-wrap items-center -mx-5">
            <div class=" w-full md:w-1/3 px-5 mb-4 lg:mb-0">
              <img class="w-full md:w-40 md:h-52 object-cover rounded-xl" src={teamone} alt=""/>
            </div>
            <div class="w-full md:w-2/3 px-5">
              <h3 class="text-xl lg:text-2xl font-semibold font-heading mb-1 text-indigo-900">Raunak Jha</h3>
              <p class="mb-3 text-indigo-800  ">Founder &amp; CEO</p>
              <p class="text-gray-500   md:text-md">Raunak Jha is a qualified Actuary from Institute of Actuaries of India (IAI) and Institute and Faculty of Actuaries, UK (IFoA) with 17 years of work experience.
She is a regular speaker in Industry forums and has co-authored research papers on technical subjects in General & Health Insurance fields.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="w-full lg:w-1/2 p-3">
        <div class="p-6 border rounded-xl bg-gray-100">
          <div class="flex flex-wrap items-center -mx-5">
            <div class="w-full md:w-1/3 px-5 mb-4 lg:mb-0">
              <img class="w-full md:w-40 md:h-52 object-cover rounded-xl" src={teamtwo} alt=""/>
            </div>
            <div class="w-full md:w-2/3 px-5">
              <h3 class="text-xl lg:text-2xl font-semibold font-heading mb-1 text-indigo-900">H Vikram Arora</h3>
              <p class="mb-3 text-indigo-800">Senior Consultant</p>
              <p class="text-gray-500">Vikram is a seasoned professional with an experience of working on business problems in technology, actuarial sciences, quantitative risk management, fraud detection / prevention, anti money laundering, business automation and social work.</p>
            </div>
          </div>
        </div>
      </div> */}
            <div class="w-full lg:w-1/2 p-3">
        <div class="p-6 border rounded-xl bg-gray-100">
          <div class="flex flex-wrap items-center -mx-5">
            <div class="w-full md:w-1/3 px-5 mb-4 lg:mb-0">
              <img class="w-full md:w-40 md:h-52 object-cover rounded-xl" src={teamfour} alt=""/>
            </div>
            <div class="w-full md:w-2/3 px-5">
              <h3 class="text-xl lg:text-2xl font-semibold font-heading mb-1 text-indigo-900">Swargesh Tripathi</h3>
              <p class="mb-3 text-indigo-800">Actuarial Analyst</p>
              <p class="text-gray-500">Swargesh is a student member of Institute of Actuaries of India with 11 papers cleared. He is specialized in the General Insurance sector and has experience in Machine Learning. He also has prior experience in employee benefit valuation. He has been a passionate teacher for the last five years teaching young minds.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg: mx-72 p-3 ">
        <div class="p-6 border rounded-xl bg-gray-100">
          <div class="flex flex-wrap items-center -mx-5">
            <div class="w-full md:w-1/3 px-5 mb-4 lg:mb-0">
              <img class="w-full md:w-40 md:h-52 object-cover rounded-xl" src={teamthree} alt=""/>
            </div>
            <div class="w-full md:w-2/3 px-5">
              <h3 class="text-xl lg:text-2xl font-semibold font-heading mb-1 text-indigo-900">Tamanna Goel</h3>
              <p class="mb-3 text-indigo-800">Actuarial Analyst</p>
              <p class="text-gray-500">Tamanna is a part-qualified Actuary and a member of both Institute of Actuaries of India and Institute and Faculty of Actuaries, UK. She has worked in Health, GI and Employee Benefits fields along with having a prior experience in Data and Business Analytics at large.</p>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="w-full lg:w-1/2 p-3">
        <div class="p-6 border rounded-xl bg-gray-100">
          <div class="flex flex-wrap items-center -mx-5">
            <div class="w-full md:w-1/3 px-5 mb-4 lg:mb-0">
              <img class="w-full md:w-40 h-52 object-cover rounded-xl" src={teamfour} alt=""/>
            </div>
            <div class="w-full md:w-2/3 px-5">
              <h3 class="text-xl lg:text-2xl font-semibold font-heading mb-1 text-indigo-900">Swargesh Tripathi</h3>
              <p class="mb-3 text-indigo-800">CEO &amp; Founder</p>
              <p class="text-gray-500">Swargesh is a student member of Institute of Actuaries of India with 11 papers cleared. He is specialized in the General Insurance sector and has experience in Machine Learning. He also has prior experience in employee benefit valuation.</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
</section>
    </div>
  )
}