import React from 'react'

import LightBackground from '../../../static/images/lightbg.png'
import SectionImage from '../../../static/images/sectiontwoimg.png'

export default function features() {
  return (
    <div>

<section class="relative pl-6 py-12 lg:py-20 lg:pl-16  overflow-hidden bg-cover bg-no-repeat bg-white" >
{/* <img class="mr-2" src={LightBackground} alt=""/> */}
  <div class="container mx-auto px-2 lg:mx-auto lg:px-4  lg:ml-24" style={{Background: `url('${LightBackground}')`, backgroundSize: 'cover', fontFamily:'poppins'}} >
    <div class="flex flex-wrap lg:-m-6">
      <div class="w-full lg:w-1/3 p-6">
        <div class="lg:max-w-lg ">
          <h2 class= "  mb-6  font-heading font-bold text-3xl lg:text-4xl hover:animate-pulse text-indigo-900 ">Make better business decisions through data driven insights</h2>
          <p class="mb-8 text-base text-indigo-900"> We help build better business world by focusing on serving organizations through innovative solutions.</p>
          <ul className='ml-4 list-disc'>
            <ul className='mb-6 text-md lg:text-lg font-semibold text-indigo-900 list-disc ml-6'>
              <li className='mb-4'> Advanced Analytical Capabilities </li>
              <li className='mb-4'> Insightful report and assessments</li>
              <li className='mb-4'> Invoking confident decisions </li>
            </ul>
          </ul>
        </div>
      </div>
      
      <div class="w-full lg:w-1/2 p-6 pb-0 lg:pb-28 ">
        <div class="bg-gradient-orange mx-auto max-w-lg h-96 rounded-3xl">
          <img class="relative top-5 mx-auto transform hover:-translate-y-16 transition ease-in-out duration-500" src={SectionImage}alt=""/>
        </div>
      </div>
    </div>
  </div>
</section> 
    </div>
  )
}
