import React from 'react'
import Background from "../../static/images/headeronebg.png"




import headone from '../../static/images/head1.png'
import headtwo from '../../static/images/head2.png'
import headthree from '../../static/images/head3.png'
import headfour from '../../static/images/head4.png'
import headfive from '../../static/images/head5.png'
import headsix from '../../static/images/head6.png'

export default function header() {
  return (
    <div>
      <section class="overflow-hidden bg-cover bg-no-repeat bg-black" style={{background: `url('${Background}')`, backgroundSize: 'cover', fontFamily:'poppins'}}>
  
  <div class="container px-10 lg:mx-auto lg:px-20">
    <div class="flex flex-wrap justify-between -m-6 pt-12 lg:pt-32 lg:pb-40 pb-10">
      <div class="w-full mt-4 lg:w-5/12 xl:w-1/2 p-6 lg:mt-24">
        <p class=" mb-4 text-sm md:text-md font-heading text-gray-400 font-medium ">A New Age Consulting firm</p>
        <p class="mb-14 font-heading text-3xl md:text-4xl md:leading-tight text-white font-bold"> The right balance of experience and expertise!</p>
        <div class="flex flex-wrap -m-3 mb-20">
          <div class="w-full lg:w-auto p-3">
            <a class="font-heading lg:w-full py-2 px-6 lg:py-4 text-base text-white bg-sky-600 hover:bg-blue-900 rounded-md" href="/contact">Enquire Now</a>
          </div>
          <div class="w-full lg:w-auto p-3">
            <a class=" font-heading lg:w-full py-2 px-6 lg:py-4 text-base text-white bg-transparent border border-gray-500 hover:border-gray-600 rounded-md" href="/about">Know More</a>
          </div>
        </div>
        <div class="lg:max-w-md">
          <div class="flex flex-wrap -m-3">
           
          </div>
        </div>
        
      </div>


      <div class=" hidden md:block w-full lg:w-7/12 xl:w-1/2 p-6">
        
        <div class="max-w-max mx-auto">

          <div class="flex flex-wrap justify-center -m-3 mb-3">
            
           <div class="w-full p-3">


              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600  to-blue-700  border-stone-50 opacity-100 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
                <a class="flex flex-wrap -m-2" href="/ActuarialConsulting">
                  <div class=" lg:w-auto lg:p-2">
                    <img src={headone} alt="" style={{width: '70px', borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2" >
                  <p class="mb-1 mt-6 text-sm text-white" >Actuarial Valuation</p>
                    
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
              <a class="flex flex-wrap -m-2" href="/EmployeeBenefits">
                  <div class=" lg:w-auto lg:p-2">
                    <img src={headtwo} alt="" style={{width: '70px',borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2">
                  <p class=" mt-4 text-sm text-white">Employee Benefits<br/>& Consulting</p>

                   
                  </div>
                </a>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600 to-blue-700  transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
              <a class="flex flex-wrap -m-2" href="/BusinessConsulting">
                  <div class="lg:w-auto lg:p-2">
                    <img src={headthree} alt="" style={{width: '70px', borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2">
                  <p class="mb-1 mt-4 text-sm text-white">Business Consulting</p>

                   
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3 mb-3 xl:-ml-20">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
              <a class="flex flex-wrap -m-2" href="/TechnologySolutions">
                  <div class=" lg:w-auto lg:p-2">
                    <img src={headfour} alt="" style={{width: '70px', borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2">
                  <p class="mb-1 mt-4 text-sm text-white">Technology <br/>Solutions</p>

                
                  </div>
                </a>
              </div>
            </div>
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
              <a class="flex flex-wrap -m-2" href="/DataMining">
                  <div class=" lg:w-auto lg:p-2">
                    <img src={headfive} alt="" style={{width: '70px', borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2">
                  <p class="mb-1 mt-4 text-sm text-white">Data Mining & <br/>Engineering</p>

                    
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-center max-w-max -m-3">
            <div class="w-full sm:w-1/2 p-3">
              <div class="mx-auto w-64 p-3 bg-gradient-to-r from-cyan-600 to-blue-700 transform hover:-translate-y-3 transition ease-out duration-1000 rounded-2xl">
              <a class="flex flex-wrap -m-2" href="/MachineLearningAI">
                  <div class=" lg:w-auto lg:p-2">
                    <img src={headsix} alt="" style={{width: '70px', borderRadius: '50%'}}/>
                  </div>
                  <div class="w-auto p-2">
                  <p class="mb-1 mt-4 text-sm text-white">Machine Learning &<br/> Artificial Intelligence</p>

                   
                  </div>
                </a>
              </div>
            </div>
       
          </div>
        </div>
      </div>
      </div>
 
  </div>
</section>

    </div>
  )
}
