import React from 'react'
import headone from '../../../static/images/head1.png'
import headtwo from '../../../static/images/head2.png'
import headthree from '../../../static/images/head3.png'
import headfour from '../../../static/images/head4.png'
import headfive from '../../../static/images/head5.png'
import headsix from '../../../static/images/head6.png'

export default function Sectionservices() {
  return (
    <div>
<section>
  {/* <div class="skew skew-top mr-for-radius ">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-900" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
    </svg>
  </div> */}
  {/* <div class="skew skew-top ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-900" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
    </svg>
  </div> */}
  <div class=" py-10 lg:py-20 lg:px-24 bg-indigo-900 radius-for-skewed" style={{fontFamily:'poppins'}}>
    <div class="container mx-auto px-4">
      <div class="mb-16 max-w-md mx-auto text-center">
        <span class="text-sky-600 font-bold" >Risk based analysis</span>
        <h2 class="text-3xl lg:text-4xl font-bold font-heading text-white">Bespoke solutions for new age problems</h2>
      </div>
      <div class="flex flex-wrap -mx-4">
        <div class="mb-8 lg:mb-0 w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headone} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text-xl sm:text-2xl font-bold font-heading text-indigo-900">Actuarial Valuation</h3>
            <p class="text-gray-500">Preparation of claims reserve estimation and adequacy assessment</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/ActuarialConsulting">View more</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headtwo} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text-xl sm:text-2xl font-bold font-heading text-indigo-900">Employee Benefits</h3>
            <p class="text-gray-500">Preparation of reports based on IAs19, IndAs19, AS15, US GAAP and others</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/EmployeeBenefits">View more</a>
          </div>
        </div>
        <div class="w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headthree} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text-xl sm:text-2xl font-bold font-heading text-indigo-900">Business Consulting</h3>
            <p class="text-gray-500">Product designing for target markets and market benchmarking</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/BusinessConsulting">View more</a>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap -mx-4 mt-10">
        <div class="mb-8 lg:mb-0 w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headfour} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text:xl sm:text-2xl font-bold font-heading text-indigo-900">Technology Solutions</h3>
            <p class="text-gray-500">Creating innovative cloud based solutions using data sciences techniques</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/TechnologySolutions">View more</a>
          </div>
        </div>
        <div class="mb-8 lg:mb-0 w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headfive} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text-xl sm:text-2xl font-bold font-heading text-indigo-900">Data Mining</h3>
            <p class="text-gray-500">Extract actionable & decision-making data using AWS, Google Cloud and Azure</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/DataMining">View more</a>
          </div>
        </div>
        <div class="w-full lg:w-1/3 px-4">
          <div class="py-12 px-6 bg-white rounded-lg shadow-md text-center">
            <span class="mb-6 inline-block p-2 rounded-md bg-indigo-800">
            <div class=" lg:w-auto">
                    <img src={headsix} alt="" style={{width: '40px', borderRadius: '50%'}}/>
                  </div>
            </span>
            <h3 class="px-8 mb-4 text-xl sm:text-2xl font-bold font-heading text-indigo-900">Machine Learning & AI</h3>
            <p class="text-gray-500">Use case identification based on company’s strategic goals</p>
            <a class="text-md font-medium text-indigo-800 underline hover:no-underline" href="/MachineLearningAI">View more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div class="skew skew-bottom mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-900" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
    </svg>
  </div>
  <div class="skew skew-bottom ml-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-900" viewbox="0 0 10 10" preserveaspectratio="none">
      <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
    </svg>
  </div> */}
</section>

    </div>
  )
}
