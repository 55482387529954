import React from "react"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Footer from "../components/footer"

import Team from "../components/AboutUs/team"
import FeaturesOne from "../components/wrexa/features"
import SectionThree from "../components/Homepage/SectionThree"
import SectionBlog from "../components/Homepage/SectionBlog"
import SectionExplainer from "../components/Homepage/SectionExplainer"

import Sectionservices from "../components/Homepage/Sectionservices"

export default function Home() {
  return <div>

    <Navbar/>
    <Header/>
    <FeaturesOne/>
    <Sectionservices/>
    <Team/>
    <SectionBlog/>
    <SectionExplainer/>
    <SectionThree/>
    
   
  
    <Footer/>

    
  </div>
}
