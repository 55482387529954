import React from 'react'
import biz from "../../../static/images/biz.jpg"
import make from "../../../static/images/make.jpg"
import tech from "../../../static/images/tech.jpg"
import acturial from "../../../static/images/actuarial.jpg"

export default function SectionBlog() {
  return (
    <div>

<section class=" pt-12 pb-16 lg:pt-24 lg:pb-36 bg-white overflow-hidden" style={{fontFamily:'poppins'}}>
  <div class="container px-4 mx-auto">
    <h2 class="mb-4 text-4xl md:text-5xl text-center font-bold font-heading font-heading tracking-px-n leading-tight text-indigo-900">Latest from Blog</h2>
    {/* <p class="lg:mb-24 font-medium text-gray-500 text-center leading-relaxed md:max-w-lg mx-auto">Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condimentum vitae vel purus.</p> */}
    <div class="flex flex-wrap   lg:ml-16">
      <div class="w-full md:w-1/2 p-8 ">
        <div class="flex flex-wrap lg:items-center -m-2 bg-slate-50">
          <div class=" w-60 lg:w-40 p-4">
            <div class="overflow-hidden rounded-xl">
              <img class="transform hover:scale-105 transition ease-in-out duration-1000" src={biz} alt=""/>
            </div>
          </div>
          <div class="flex-1 p-4">
            <div class="md:max-w-xs">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-6">
                  <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">Insurance | Raunak Jha</p>
                  <a class="inline-block hover:text-gray-800 hover:underline" href="/blogs/roleofactuaries/">
                    <h3 class="text-xl font-semibold leading-normal text-indigo-800">Parametric Insurance – Role of Actuaries</h3>
                  </a>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="flex flex-wrap lg:items-center -m-4 bg-slate-50">
          <div class="w-60 lg:w-40  p-4">
            <div class="overflow-hidden rounded-xl">
              <img class="transform hover:scale-105 transition ease-in-out duration-1000" src={make} alt=""/>
            </div>
          </div>
          <div class="flex-1 p-4">
            <div class="md:max-w-xs">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-6">
                  <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">Underwriting | Vikram Arora</p>
                  <a class="inline-block hover:text-gray-800 hover:underline" href="/blogs/UnderwritingScoringModel">
                    <h3 class="text-xl font-semibold leading-normal text-indigo-800">Underwriting Scoring Models</h3>
                  </a>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="flex flex-wrap lg:items-center -m-4 rounded-lg bg-slate-50">
          <div class=" w-60 lg:w-40  p-4">
            <div class="overflow-hidden rounded-xl">
              <img class="transform hover:scale-105 transition ease-in-out duration-1000" src={tech} alt=""/>
            </div>
          </div>
          <div class="flex-1 p-4">
            <div class="md:max-w-xs">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-6">
                  <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">Underwriting | Tamanna Goel</p>
                  <a class="inline-block hover:text-gray-800 hover:underline" href="/blogs/ActuarialPerspective">
                    <h3 class="text-xl font-semibold leading-normal text-indigo-800">Underwriting – An Introduction from Actuarial Perspective</h3>
                  </a>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="flex flex-wrap lg:items-center -m-4 bg-slate-50">
          <div class=" w-60 lg:w-40  p-4">
            <div class="overflow-hidden rounded-xl">
              <img class="transform hover:scale-105 transition ease-in-out duration-1000" src={acturial} alt=""/>
            </div>
          </div>
          <div class="flex-1 p-4">
            <div class="md:max-w-xs">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-6">
                  <p class="mb-1.5 text-sm text-indigo-800 font-medium  tracking-px">ERM | Raunak Jha</p>
                  <a class="inline-block hover:text-gray-800 hover:underline" href="/blogs/EnterpriseRisk">
                    <h3 class="text-xl font-semibold leading-normal text-indigo-800">Looking at ERM from the perspective of a STARTUP</h3>
                  </a>
                </div>
                {/* <div class="flex flex-wrap items-center -m-1">
                  <div class="w-auto p-1">
                    <img src="flaro-assets/images/blog/blog-avatar4.png" alt=""/>
                  </div>
                  <div class="w-auto p-1">
                    <p class="text-sm font-semibold leading-relaxed text-indigo-800">Jerome Bell</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
